import {
  offClickProductCardInInformationFlow,
  offClickProductCardInList,
  offOpenQuickCartInList,
  offOpenQuickCartResultInList,
  offOpenQuickCartResultInPickList,
  offReEnterInInformationFlow,
  offReEnterInList,
  offReEnterInPickList,
} from '../eventOff'
import {
  onOpenQuickCartResultInList,
  onOpenQuickCartInList,
  onClickProductCardInList,
  onClickProductCardInInformationFlow,
  onOpenQuickCartResultInPickList,
  onReEnterInInformationFlow,
  onReEnterInList,
  onReEnterInPickList,
} from '../eventOn'

const clickPushBackEventType = {
  reEnter: 'reEnter',
  reEnterPick: 'reEnterPick',
  openQuickCart: 'openQuickCart', // 加车
  openQuickCartResult: 'openQuickCartResult', // 加车结果（关闭、完成加车）
  clickProductCard: 'clickProductCard', // 点击商卡
}

/**
 * 注册列表触发搜索词点后推请求的行为（点击、加车事件）
 * 1. 点击列表商品
 * 2. 点击列表加车
 * 3. 商详点击加车
 * @param {Function} eventCb 事件回调
 * @param {String[]} watchList 监听行为
 */
const onTouchCPBSRequestInList = (
  eventCb,
  watchList = [
    clickPushBackEventType.clickProductCard,
    clickPushBackEventType.openQuickCart,
  ],
) => {
  let openQuickCartCb
  let clickProductCardCb
  if (watchList.includes(clickPushBackEventType.openQuickCart)) {
    // 列表加车
    openQuickCartCb = args => {
      eventCb({ type: clickPushBackEventType.openQuickCart, params: args })
    }
    onOpenQuickCartInList(openQuickCartCb)
  }
  if (watchList.includes(clickPushBackEventType.clickProductCard)) {
    // 点击列表商卡
    clickProductCardCb = args => {
      eventCb({ type: clickPushBackEventType.clickProductCard, params: args })
    }
    onClickProductCardInList(clickProductCardCb)
  }
  // todo 商详加车（对比点击商品是否一致）

  // 注销
  const offFunc = () => {
    if (watchList.includes(clickPushBackEventType.openQuickCart)) {
      // 列表加车
      offOpenQuickCartInList(openQuickCartCb)
    }
    if (watchList.includes(clickPushBackEventType.clickProductCard)) {
      // 点击列表商卡
      offClickProductCardInList(clickProductCardCb)
    }
  }
  return offFunc
}

/**
 * 注册信息流触发搜索词点后推请求的行为（点击、加车事件）
 * 1. 点击信息流商品
 * 2. 点击信息流落地页加车结果
 * 3. 商详点击加车
 * @param {Function} eventCb 事件回调
 * @param {String[]} watchList 监听行为
 */
const onTouchCPBSRequestInInformationFlow = (
  eventCb,
  watchList = [
    clickPushBackEventType.reEnterPick,
    clickPushBackEventType.clickProductCard,
    clickPushBackEventType.openQuickCartResult,
  ],
) => {
  let openQuickCartCb
  let clickProductCardCb
  let reEnterPickCb
  // 信息流落地页加车结果
  if (watchList.includes(clickPushBackEventType.reEnterPick)) {
    reEnterPickCb = args => {
      eventCb({
        type: clickPushBackEventType.reEnterPick,
        params: args,
      })
    }
    onReEnterInPickList(reEnterPickCb)
  }
  // 信息流落地页加车结果
  if (watchList.includes(clickPushBackEventType.openQuickCartResult)) {
    openQuickCartCb = args => {
      eventCb({
        type: clickPushBackEventType.openQuickCartResult,
        params: args,
      })
    }
    onOpenQuickCartResultInPickList(openQuickCartCb)
  }
  // 点击信息流商卡
  if (watchList.includes(clickPushBackEventType.clickProductCard)) {
    clickProductCardCb = args => {
      eventCb({ type: clickPushBackEventType.clickProductCard, params: args })
    }
    onClickProductCardInInformationFlow(clickProductCardCb)
  }
  // todo 商详加车（对比点击商品是否一致）
  // 点击的商品id，防止来源错误
  // let clickedGoodsId

  // 注销
  const offFunc = () => {
    if (watchList.includes(clickPushBackEventType.reEnterPick)) {
      // 点击信息流商卡
      offReEnterInPickList(reEnterPickCb)
    }
    if (watchList.includes(clickPushBackEventType.openQuickCart)) {
      // 信息流落地页加车结果
      offOpenQuickCartResultInPickList(openQuickCartCb)
    }
    if (watchList.includes(clickPushBackEventType.clickProductCard)) {
      // 点击信息流商卡
      offClickProductCardInInformationFlow(clickProductCardCb)
    }
  }
  return offFunc
}

/**
 * 注册列表触发搜索词点后推展示的行为（返回页面、加车事件）
 * 1. 返回页面
 * 2. 点击信息流落地页加车
 * @param {Function} eventCb 事件回调
 * @param {String[]} watchList 监听行为
 */
const onTouchCPBSShowInList = (
  eventCb,
  watchList = [
    clickPushBackEventType.reEnter,
    clickPushBackEventType.openQuickCartResult,
  ],
) => {
  let openQuickCartResultCb
  let reEnterCb
  // 列表加车结果
  if (watchList.includes(clickPushBackEventType.openQuickCartResult)) {
    openQuickCartResultCb = args => {
      eventCb({
        type: clickPushBackEventType.openQuickCartResult,
        params: args,
      })
    }
    onOpenQuickCartResultInList(openQuickCartResultCb)
  }
  // 返回列表页面
  if (watchList.includes(clickPushBackEventType.reEnter)) {
    reEnterCb = args => {
      eventCb({ type: clickPushBackEventType.reEnter, params: args })
    }
    onReEnterInList(reEnterCb)
  }
  // todo 商详加车（对比点击商品是否一致）

  // 注销
  const offFunc = () => {
    if (watchList.includes(clickPushBackEventType.openQuickCartResult)) {
      // 列表加车结果
      offOpenQuickCartResultInList(openQuickCartResultCb)
    }
    if (watchList.includes(clickPushBackEventType.reEnter)) {
      // 返回列表页面
      offReEnterInList(reEnterCb)
    }
  }
  return offFunc
}

/**
 * 注册信息流触发搜索词点后推展示的行为（返回页面、加车事件）
 * 1. 返回页面
 * 2. 点击信息流落地页加车
 * @param {Function} eventCb 事件回调
 * @param {String[]} watchList 监听行为
 */
const onTouchCPBSShowInInformationFlow = (
  eventCb,
  watchList = [clickPushBackEventType.reEnter],
) => {
  let reEnterCb
  // 返回信息流
  if (watchList.includes(clickPushBackEventType.reEnter)) {
    reEnterCb = args => {
      eventCb({ type: clickPushBackEventType.reEnter, params: args })
    }
    onReEnterInInformationFlow(reEnterCb)
  }

  // 注销
  const offFunc = () => {
    if (watchList.includes(clickPushBackEventType.reEnter)) {
      // 返回信息流
      offReEnterInInformationFlow(reEnterCb)
    }
  }
  return offFunc
}

export {
  clickPushBackEventType,
  onTouchCPBSRequestInList,
  onTouchCPBSRequestInInformationFlow,
  onTouchCPBSShowInList,
  onTouchCPBSShowInInformationFlow,
}
