import {
  OPEN_QUICK_CART_IN_PICKED_LIST,
  OPEN_QUICK_CART_RESULT_IN_PICKED_LIST,
  CLICK_PRODUCT_CARD_IN_PICKED_LIST,
  CLICK_PRODUCT_CARD_IN_GOODS_DETAIL,
  CLICK_PRODUCT_CARD_IN_INFORMATION_FLOW,
  CLICK_PRODUCT_CARD_IN_LIST,
  OPEN_QUICK_CART_IN_LIST,
  OPEN_QUICK_CART_RESULT_IN_LIST,
  REENTER_IN_LIST,
  REENTER_IN_INFORMATION_FLOW,
  REENTER_IN_PICKED_LIST,
  SHOW_PRODUCT_CARD_POPUP,
} from './eventNameConstant'

/**
 * 注销商详页点击商卡事件
 */
const offClickProductCardInGoodsDetail = eventCb => {
  eventBus.off(CLICK_PRODUCT_CARD_IN_GOODS_DETAIL, eventCb)
}

/**
 * 注销信息流落地页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const offClickProductCardInPickList = eventCb => {
  eventBus.off(CLICK_PRODUCT_CARD_IN_PICKED_LIST, eventCb)
}

/**
 * 注销信息流点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const offClickProductCardInInformationFlow = eventCb => {
  eventBus.off(CLICK_PRODUCT_CARD_IN_INFORMATION_FLOW, eventCb)
}

/**
 * 注销信息流落地点击商卡加车事件
 * @param {Function} eventCb 事件回调
 */
const offOpenQuickCartInPickList = eventCb => {
  eventBus.off(OPEN_QUICK_CART_IN_PICKED_LIST, eventCb)
}

/**
 * 注销信息流落地页点击商卡加车事件
 * @param {Function} eventCb 事件回调
 */
const offOpenQuickCartResultInPickList = eventCb => {
  eventBus.off(OPEN_QUICK_CART_RESULT_IN_PICKED_LIST, eventCb)
}

/**
 * 注销列表页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const offClickProductCardInList = eventCb => {
  eventBus.off(CLICK_PRODUCT_CARD_IN_LIST, eventCb)
}

/**
 * 注销列表页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const offOpenQuickCartResultInList = eventCb => {
  eventBus.off(OPEN_QUICK_CART_RESULT_IN_LIST, eventCb)
}

/**
 * 注销列表页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const offOpenQuickCartInList = eventCb => {
  eventBus.off(OPEN_QUICK_CART_IN_LIST, eventCb)
}

/**
 * 注册列表页重新进入页面
 * @param {Function} eventCb 事件回调
 */
const offReEnterInList = eventCb => {
  eventBus.off(REENTER_IN_LIST, eventCb)
}

/**
 * 注册信息流落地页重新进入页面
 * @param {Function} eventCb 事件回调
 */
const offReEnterInInformationFlow = eventCb => {
  eventBus.off(REENTER_IN_INFORMATION_FLOW, eventCb)
}

/**
 * 注册信息流落地页重新进入页面
 * @param {Function} eventCb 事件回调
 */
const offReEnterInPickList = eventCb => {
  eventBus.off(REENTER_IN_PICKED_LIST, eventCb)
}


/**
 * 展示商卡弹窗
 * @param {Function} eventCb 事件回调
 */
const offShowProductCardPopup = eventCb => {
  eventBus.off(SHOW_PRODUCT_CARD_POPUP, eventCb)
}


export {
  offClickProductCardInGoodsDetail,
  offClickProductCardInInformationFlow,
  offClickProductCardInPickList,
  offOpenQuickCartInPickList,
  offOpenQuickCartResultInPickList,
  offClickProductCardInList,
  offOpenQuickCartInList,
  offOpenQuickCartResultInList,
  offReEnterInList,
  offReEnterInInformationFlow,
  offReEnterInPickList,
  offShowProductCardPopup,
}
