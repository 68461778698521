// 点后推搜索词
import {
  clickPushBackEventType,
  onTouchCPBSRequestInList,
  onTouchCPBSShowInList,
} from '../../mounting/eventBus/eventLib/compose/clickPushBackSearch'
import { getListPageInfo } from 'public/src/pages/product_list_v2/js/utils'
import {
  CPBSComponentTableInfo,
  createCPBSPopup,
  requestRecommendSearchWords,
} from './clickPushBackSearchUtils'
import { getFeedbackRecInSession } from './utils'
import { abtservice } from 'public/src/services/abt'
import { onReEnterInList } from '../../mounting/eventBus/eventLib/eventOn'
import { offReEnterInList } from '../../mounting/eventBus/eventLib/eventOff'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
// import { emitShowProductCardPopup } from '../../mounting/eventBus/eventLib/eventEmit'

export default {
  data() {
    return {
      pageInfo: {
        isSearch: false,
        isSelectListPage: false,
        isEntityListPage: false,
        isFormCat: '',
        isFormSbc: '',
      },
      cpbsTimingAbt: '',
      cpbsStyleAbt: '',
      cpbsGoodIdList: [],
      cpbsAbtTest: '',
      // 注销事件订阅
      offTouchCPBSRequestInList: null,
      offTouchCPBSShowInList: null,
      // 请求
      requestCPBSPromise: null,
      listComponentActive: true,
    }
  },
  mounted() {
    this.handleInitPageCPBSInfo()
    onReEnterInList(this.awaithandleInitPageCPBSInfo)
  },
  watch: {
    pageInfo: {
      handler() {
        this.handleInitCPBSWatcher()
      },
      deep: true,
    },
  },
  activated() {
    this.listComponentActive = true
  },
  deactivated() {
    this.listComponentActive = false
  },
  destroyed() {
    this.offCPBSWatcher()
    offReEnterInList(this.awaithandleInitPageCPBSInfo)
  },
  methods: {
    handleRequestCPBS({ type, params }) {
      this.requestCPBSPromise = null

      // 当前列表不展示
      if (!this.listComponentActive) {
        return
      }
      // 点后推搜索词组件进入的搜索结果页，即便命中了点后推开关也不展示点后推组件；
      if (this.pageInfo.isSearch) {
        let { query } = this.$route
        if (query?.isBlockClickPushBackSearch) {
          return
        }
      }
      // 若户点击同一入口坑位2次及以上时，不再触发点后推搜索词
      if (this.cpbsGoodIdList.includes(`${params?.product?.goods_id}`)) {
        return
      }

      // 触发非本列表商品
      if (this.waterItems.every(item => item.goods_id !== params?.product?.goods_id)) {
        return
      }

      // 需被点击的商卡在屏幕内完全露出 且 距离屏幕边缘有40pt的宽度阈值，才推出点后推搜索词
      if (this.cpbsStyleAbt === 'B') {
        const itemEl = this.getProductListItemInstance(params?.index)
        if (!itemEl?.$el) {
          return
        }
        const itemSize = itemEl.$el?.getBoundingClientRect()
        const bottomHeight = window.innerHeight - itemSize.bottom
        const topHeight = itemSize.top
        // todo 确认顶部高度
        // 100为页面头部高度
        if (bottomHeight < 40 || topHeight < 40 + 100) {
          return
        }
      }

      let requestParams = {}
      if (
        [
          clickPushBackEventType.clickProductCard,
          clickPushBackEventType.openQuickCart,
        ].includes(type)
      ) {
        let cate_id = ''
        switch (true) {
          case this.pageInfo.isSelectListPage: {
            cate_id = this.catInfo?.select_id
            break
          }
          case this.pageInfo.isEntityListPage: {
            cate_id = this.catInfo?.cat_id
            break
          }
        }
        const keywords = this.pageInfo.isSearch ? this.catInfo?.searchWord : ''
        requestParams = {
          goods_id: params?.product?.goods_id,
          goods_cate_id: params?.product?.cat_id,
          cate_id,
          use_scene: 1, // 列表、搜索、信息流点后推
          click_card_value: this.cpbsStyleAbt,
          keywords,
        }
        this.requestCPBSPromise = new Promise(resolve => {
          requestRecommendSearchWords(requestParams).then(res => {
            if (res?.wordList && ['A', 'C'].includes(this.cpbsStyleAbt)) {
              // 预加载组件
              CPBSComponentTableInfo[this.cpbsStyleAbt].comp()
            }
            resolve({
              product: params?.product,
              index: params?.index,
              searchWordsInfo: res,
              keywords, // 搜索页当前搜索词
            })
          })
        })
      }
    },
    async handleShowCPBS({ type, params }) {
      // 当前列表不展示
      if (!this.listComponentActive) {
        return
      }
      if (type === clickPushBackEventType.reEnter) {
        if (!params?.isBackFromDetail) {
          return
        }
        // 商详返回，加车条件判断
        if (['addbag', 'noaddbag'].includes(this.cpbsTimingAbt)) {
          const detailAddCartInfo = getFeedbackRecInSession()
          if (detailAddCartInfo?.goodsId) {
            const quickCartSuccess = !!detailAddCartInfo?.addBagStatus
            if (
              (quickCartSuccess && this.cpbsTimingAbt === 'noaddbag') ||
              (!quickCartSuccess && this.cpbsTimingAbt === 'addbag')
            ) {
              return
            }
          } else {
            return
          }
        }
      }
      if (type === clickPushBackEventType.openQuickCartResult) {
        const quickCartSuccess = !!params?.addBagStatus
        if (
          (quickCartSuccess && this.cpbsTimingAbt === 'noaddbag') ||
          (!quickCartSuccess && this.cpbsTimingAbt === 'addbag')
        ) {
          return
        }
      }
      if (this.requestCPBSPromise) {
        const res = await this.requestCPBSPromise
        console.log('点后推接口数据', res)
        // 若户点击同一入口坑位2次及以上时，不再触发点后推搜索词
        if (this.cpbsGoodIdList.includes(`${res?.product?.goods_id}`)) {
          return
        }

        const index = res?.index || 0
        if (res?.searchWordsInfo?.wordList) {
          let propsParams = {
            language: this.language,
            listData: res?.searchWordsInfo?.wordList,
            afterCardInfo: res?.searchWordsInfo?.afterCardInfo,
            sourceGoodsId: `${res?.product?.goods_id}`,
            traceId: res?.searchWordsInfo?.traceId,
            resultContent: res?.searchWordsInfo?.resultContent,
            abTestStr: this.cpbsAbtTest,
          }
          // 商卡弹窗
          if (['A', 'C'].includes(this.cpbsStyleAbt)) {
            // 暂存使用evantBus的方式
            // emitShowProductCardPopup({
            //   ...res
            // })
            const itemEl = this.getProductListItemInstance(index)
            if (!itemEl?.$el) {
              console.error('找不到商卡元素')
            }

            if (this.cpbsStyleAbt === 'A') {
              Object.assign(propsParams, {
                catName: this.language?.SHEIN_KEY_PWA_34249,
                needCcc: true,
              })
            } else if (this.cpbsStyleAbt === 'C') {
              Object.assign(propsParams, {
                titleText: this.language?.SHEIN_KEY_PWA_32040,
              })
            }
            createCPBSPopup({
              parentEl: itemEl.$el,
              params: propsParams,
              popupTypeBranch: this.cpbsStyleAbt,
              router: this.$router,
            })
            // 商品下方插入元素
          } else if (this.cpbsStyleAbt === 'B') {

            // 插入数据,数据进栈尾
            // this.goods?.push(searchWordsContent)
            Object.assign(propsParams, {
              type: 'recommend-search-card',
              titleText: this.language?.SHEIN_KEY_PWA_32040,
              localIndex: index,
              abTestStr: this.cpbsAbtTest,
            })

            this.goods?.push(propsParams)
          }
          // 已推出点后推搜索词的坑位的上5坑位和下5坑位，不再触发点后推搜索词
          for (let i = 1; i <= 5; i++) {
            if (index - i >= 0) {
              this.cpbsGoodIdList.push(`${this.waterItems[index - i]?.goods_id}`)
            }
            this.cpbsGoodIdList.push(`${this.waterItems[index + i]?.goods_id}`)
          }
          this.cpbsGoodIdList.push(`${res?.product?.goods_id}`)
        }
      }
    },
    // 根据页面类型与abt判断是否需要点后推搜索词
    async handleCPBSAbt() {
      if (
        !this.pageInfo.isSearch &&
        !this.pageInfo.isSelectListPage &&
        !this.pageInfo.isEntityListPage
      ) {
        return false
      }
      let poskey = ''
      let enterValue = ''
      let styleValue = ''
      switch (true) {
        case this.pageInfo.isSearch: {
          poskey = 'PsearchClickReco'
          enterValue = 'PsearchClickRecoEntry'
          styleValue = 'PsearchClickRecoCard'
          break
        }
        case this.pageInfo.isSelectListPage:
        case this.pageInfo.isEntityListPage: {
          poskey = 'LickClickReco'
          enterValue = 'LickClickRecoEntry'
          styleValue = 'LickClickRecoCard'
          break
        }
      }
      const abtResult = await getUserAbtData()
      const cpbsTimingAbt =
        abtResult?.[poskey]?.p?.[enterValue]
      const cpbsStyleAbt = abtResult?.[poskey]?.p?.[styleValue]
      let abtInfo = {}
      abtInfo[poskey] = abtResult?.[poskey]
      // 注意middlewares/product_list/dependencies.js中的声明，为了解决abt中的child被过滤问题（即使在客户端请求文件中配置了对应的abt也不行;）
      // eslint-disable-next-line @shein-aidc/abt/abt
      this.cpbsAbtTest = abtservice.getUserAbtResultForAnalysis({
        posKeys: poskey,
        abtInfo,
      }).sa
      this.cpbsTimingAbt = cpbsTimingAbt
      this.cpbsStyleAbt = cpbsStyleAbt
      if (
        !['click', 'addbag', 'noaddbag'].includes(cpbsTimingAbt) ||
        !cpbsStyleAbt
      )
        return false
      if (this.pageInfo.isSelectListPage || this.pageInfo.isEntityListPage) {
        const listPageFormAbt =
          abtResult?.LickClickReco?.p?.LickClickRecoEntrance
        return (
          !listPageFormAbt ||
          (listPageFormAbt === 'sbc' && this.pageInfo.isFormSbc) ||
          (listPageFormAbt === 'category' && this.pageInfo.isFormCat)
        )
      }
      return true
    },
    async handleInitCPBSWatcher() {
      this.offCPBSWatcher()
      if (!await this.handleCPBSAbt()) {
        return
      }
      const requestWatchList = [
        clickPushBackEventType.clickProductCard,
        this.cpbsTimingAbt === 'click'
          ? ''
          : clickPushBackEventType.openQuickCart,
      ].filter(Boolean)
      const showWatchList = [
        clickPushBackEventType.reEnter,
        this.cpbsTimingAbt === 'click'
          ? ''
          : clickPushBackEventType.openQuickCartResult,
      ].filter(Boolean)
      this.offTouchCPBSRequestInList = onTouchCPBSRequestInList(
        this.handleRequestCPBS,
        requestWatchList,
      )
      this.offTouchCPBSShowInList = onTouchCPBSShowInList(
        this.handleShowCPBS,
        showWatchList,
      )
    },
    offCPBSWatcher() {
      if (typeof this.offTouchCPBSRequestInList === 'function') {
        this.offTouchCPBSRequestInList()
      }
      if (typeof this.offTouchCPBSShowInList === 'function') {
        this.offTouchCPBSShowInList()
      }
    },
    // 在路由更新后再获取路由信息
    awaithandleInitPageCPBSInfo() {
      setTimeout(() => {
        this.handleInitPageCPBSInfo()
      }, 500)
    },
    handleInitPageCPBSInfo() {
      // 因为共用container + keep-alive原因，所以路由更新也更新下页面类型
      let { query, meta } = this.$route
      // 不用catInfo的原因是： 从个人中心切到category 再进列表的时候catInfo居然无值，导致判断页面类型异常
      let pageInfos = getListPageInfo({
        search_type: query?.search_type,
        type: meta?.type,
      })
      this.pageInfo.isSearch = pageInfos?.isSearch
      this.pageInfo.isSelectListPage = pageInfos?.isSelectListPage
      this.pageInfo.isEntityListPage = pageInfos?.isEntityListPage
      this.pageInfo.isFormCat = query?.srctype === 'category' || query?.nav_src_type === 'category'
      this.pageInfo.isFormSbc = query?.entranceType === 'sbc' || query?.nav_entrance_type === 'sbc'
    },
  },
}
