import {
  OPEN_QUICK_CART_IN_PICKED_LIST,
  OPEN_QUICK_CART_RESULT_IN_PICKED_LIST,
  CLICK_PRODUCT_CARD_IN_PICKED_LIST,
  CLICK_PRODUCT_CARD_IN_GOODS_DETAIL,
  CLICK_PRODUCT_CARD_IN_INFORMATION_FLOW,
  CLICK_PRODUCT_CARD_IN_LIST,
  OPEN_QUICK_CART_IN_LIST,
  OPEN_QUICK_CART_RESULT_IN_LIST,
  REENTER_IN_LIST,
  REENTER_IN_INFORMATION_FLOW,
  REENTER_IN_PICKED_LIST,
  SHOW_PRODUCT_CARD_POPUP,
} from './eventNameConstant'

/**
 * 注册商详页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const onClickProductCardInGoodsDetail = eventCb => {
  eventBus.on(CLICK_PRODUCT_CARD_IN_GOODS_DETAIL, eventCb)
}

/**
 * 注册信息流点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const onClickProductCardInInformationFlow = eventCb => {
  eventBus.on(CLICK_PRODUCT_CARD_IN_INFORMATION_FLOW, eventCb)
}

/**
 * 注册信息流落地页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const onClickProductCardInPickList = eventCb => {
  eventBus.on(CLICK_PRODUCT_CARD_IN_PICKED_LIST, eventCb)
}

/**
 * 注册信息流落地点击商卡加车结果事件
 * @param {Function} eventCb 事件回调
 */
const onOpenQuickCartResultInPickList = eventCb => {
  eventBus.on(OPEN_QUICK_CART_RESULT_IN_PICKED_LIST, eventCb)
}

/**
 * 注册信息流落地点击商卡加车事件
 * @param {Function} eventCb 事件回调
 */
const onOpenQuickCartInPickList = eventCb => {
  eventBus.on(OPEN_QUICK_CART_IN_PICKED_LIST, eventCb)
}

/**
 * 注册列表页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const onClickProductCardInList = eventCb => {
  eventBus.on(CLICK_PRODUCT_CARD_IN_LIST, eventCb)
}

/**
 * 注册列表页点击商卡结果事件
 * @param {Function} eventCb 事件回调
 */
const onOpenQuickCartResultInList = eventCb => {
  eventBus.on(OPEN_QUICK_CART_RESULT_IN_LIST, eventCb)
}

/**
 * 注册列表页点击商卡事件
 * @param {Function} eventCb 事件回调
 */
const onOpenQuickCartInList = eventCb => {
  eventBus.on(OPEN_QUICK_CART_IN_LIST, eventCb)
}

/**
 * 注册列表页重新进入页面
 * @param {Function} eventCb 事件回调
 */
const onReEnterInList = eventCb => {
  eventBus.on(REENTER_IN_LIST, eventCb)
}

/**
 * 注册信息流落地页重新进入页面
 * @param {Function} eventCb 事件回调
 */
const onReEnterInInformationFlow = eventCb => {
  eventBus.on(REENTER_IN_INFORMATION_FLOW, eventCb)
}


/**
 * 注册信息流落地页重新进入页面
 * @param {Function} eventCb 事件回调
 */
const onReEnterInPickList = eventCb => {
  eventBus.on(REENTER_IN_PICKED_LIST, eventCb)
}

/**
 * 展示商卡弹窗
 * @param {Function} eventCb 事件回调
 */
const onShowProductCardPopup = eventCb => {
  eventBus.on(SHOW_PRODUCT_CARD_POPUP, eventCb)
}

export {
  onClickProductCardInGoodsDetail,
  onClickProductCardInInformationFlow,
  onClickProductCardInPickList,
  onOpenQuickCartInPickList,
  onOpenQuickCartResultInPickList,
  onClickProductCardInList,
  onOpenQuickCartInList,
  onOpenQuickCartResultInList,
  onReEnterInList,
  onReEnterInInformationFlow,
  onReEnterInPickList,
  onShowProductCardPopup,
}
