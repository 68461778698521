// 点后推搜索词通用方法

import schttp from 'public/src/services/schttp'

let recommendSearchWordsInstance
export async function requestRecommendSearchWords(data) {
  try {
    if (recommendSearchWordsInstance) {
      recommendSearchWordsInstance.abort()
    }
    recommendSearchWordsInstance = new SchttpAbortCon()
    const res = await schttp({
      url: '/category-api/feedback/common-search-word',
      method: 'GET',
      useBffApi: true,
      signal: recommendSearchWordsInstance.signal,
      params: data,
    })
    return Promise.resolve(res?.info || {})
  } catch (e) {
    return Promise.resolve({})
  }
}

// 针对点后推搜索词的两种弹窗样式，分别对应不同的组件
export const CPBSComponentTableInfo = {
  // 半遮盖商卡样式 弹窗组件 对应A分支
  A: {
    mountDom: '.product-card__img-bottom',
    comp: () =>
      import(
        /* webpackChunkName: "RecommendCommonCardPopup" */ 'public/src/pages/product_list_v2/components/CardRecommend/RecommendCommonCardPopup.vue'
      ),
  },
  // 全遮盖商卡样式 弹窗组件 对应C分支
  C: {
    mountDom: '.all-wrapper__bottom-top',
    comp: () =>
      import(
        /* webpackChunkName: "RecommendSearchAllCardPopup" */ 'public/src/pages/product_list_v2/components/CardRecommend/RecommendSearchAllCardPopup.vue'
      ),
  },
}
export async function createCPBSPopup({
  parentEl,
  params,
  popupTypeBranch,
  router,
  onOpenPopup,
  onClosePopup,
}) {
  // 动态导入组件
  const component = await CPBSComponentTableInfo[popupTypeBranch].comp()
  const CardPopup = Vue.extend({ ...component.default, router })
  let originEventExpose = ''
  let daEventEl = null
  // 使用旧插槽实现的备份代码
  // parentEl.$refs.CardRecommend.$refs.searchWordAllCard.showRecommendRecPopup({ show: true })
  if (typeof onOpenPopup === 'function') {
    onOpenPopup({
      parentEl,
      popupTypeBranch,
    })
  }

  // 针对C类型点推弹窗
  if (popupTypeBranch === 'C') {
    // 展示C类型弹窗时（重新进入页面）不上报埋点
    daEventEl = parentEl.querySelector('[da-event-click]')
    originEventExpose = daEventEl?.getAttribute('da-event-expose')
    daEventEl?.setAttribute('da-event-expose', '')

    // 当商卡为1:1时，动态修改商卡高度后
    // 290是弹窗的最大高度
    let minCardHeight = 290 - parentEl.clientHeight
    if (minCardHeight > 0) {
      parentEl.style.setProperty(
        '--product-card-padding-bottom',
        `${minCardHeight || 0}px`,
      )
    }
    //  else {
    //   // 当前商卡高度大于C类型的弹窗，动态修改商卡高度(为弹窗实际渲染的DOM高度)
    //   // parentEl.style.setProperty(
    //   //   '--product-card-max-height',
    //   //   `${290 || 0}px`,
    //   // )
    // }
  }
  // 创建组件实例并挂载
  const cardPopupComponent = new CardPopup({ propsData: params }).$mount()

  // 为组件实例添加关闭事件监听（针对C类型点推弹窗，当商卡为1:1时，上面动态修改商卡高度后，点击弹窗关闭按钮后需要恢复商卡的高）
  popupTypeBranch === 'C' &&
    cardPopupComponent.$on('closeFromIcon', () => {
      if (typeof onClosePopup === 'function') {
        onClosePopup({
          parentEl,
          popupTypeBranch,
        })
      }
      parentEl.style.setProperty('--product-card-padding-bottom', 0)
      parentEl.style.setProperty('--product-card-max-height', '100%')
      // 关闭C类型弹窗时上报商卡埋点
      if (daEventEl && originEventExpose) {
        daEventEl.setAttribute('da-event-expose', originEventExpose)
        // 手动触发一次上报
        daEventCenter.triggerNotice({
          daId: originEventExpose,
          target: [daEventEl],
        })
      }
    })
  // 将组件的根元素插入到挂载点中，而不是替换它
  // parentEl.appendChild(cardPopupComponent.$el)
  parentEl
    ?.querySelector?.(CPBSComponentTableInfo[popupTypeBranch].mountDom || '')
    ?.appendChild(cardPopupComponent.$el)

  // 确保组件实例已经创建并挂载
  Vue.nextTick(() => {
    // 调用组件的方法打开展示组件
    if (typeof cardPopupComponent.showRecommendRecPopup === 'function') {
      cardPopupComponent.showRecommendRecPopup({ show: true, mountFatherDom: parentEl })
    } else {
      console.error('showRecommendRecPopup 方法未定义')
    }
  })
}
